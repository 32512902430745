import {
  GridColDef,
  GridPaginationModel,
  GridSortModel
} from '@mui/x-data-grid';
import oppurtuniyColumn from 'assets/list/dashboard/column';
import DefaultDashboardFields from 'assets/list/dashboard/default-column';
import listQueryString, { prepareSort } from 'helpers/query-string-helper';
import React, { useCallback, useEffect, useState } from 'react';
import { ObjectType } from 'types';
import {
  PropertiesFilterEntity,
  PropertiesFilterPropTypes
} from 'types/dashboard-type';
import defaultSort from 'assets/list/dashboard/default-sort';
import opportunityService from 'services/opportunity-service';
import userPreferenceService from 'services/user-preference-service';
import fieldLabel from 'assets/constants/fieldLabel';
import eventBus from 'helpers/event-bus-helper';

import DataGrid, { DataGridProp } from 'components/data-grid';
import DASHBOARDS from 'assets/constants/dashboards';

import { ChangeEvent, InputChangeEvent } from 'types/common-types';
import initilaPropertiesFilter from 'state/properties/initial-filter';

interface DashboardComponentProps {
  Filter: React.FC<PropertiesFilterPropTypes>;
  dashboard: string;
  prepareFilterString: (
    query: string,
    filter: PropertiesFilterEntity
  ) => string;
}

const PropertiesHOC = (DGComponent: (params: DataGridProp) => JSX.Element) => {
  function Component({
    Filter,
    dashboard,
    prepareFilterString
  }: DashboardComponentProps) {
    const [refreshColumn, setRefreshColumn] = useState(0);
    const [properties, setProperties] = useState<ObjectType[]>([]);

    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false);

    const [selectedColumns, setSelectedColumns] = useState<string[]>(
      DefaultDashboardFields.TRANSACTION_DASHBOARD_DD_ENDING
    );
    const [rowCountState, setRowCountState] = React.useState(0);

    const [paginationModel, setPaginationModel] =
      React.useState<GridPaginationModel>({
        pageSize: 25,
        page: 0
      });

    const [sortModel, setSortModel] = React.useState<GridSortModel>([
      defaultSort
    ]);

    const [filter, setFilter] = useState<PropertiesFilterEntity>(
      initilaPropertiesFilter
    );
    const [isFilterChanged, setIsFilterChanged] = useState<boolean>(false);

    const [initialLoad, setInitialLoad] = useState<boolean>(false);
    const [query, setQuery] = useState('');

    const updateFilter = (e: ChangeEvent | InputChangeEvent) => {
      setFilter(Object.assign({}, filter, { [e.target.name]: e.target.value }));
    };

    const getColumns = useCallback(() => {
      return oppurtuniyColumn(false, dashboard).filter((x: GridColDef) =>
        selectedColumns.includes(x.field)
      );
    }, [selectedColumns]);

    const showAll = (queryUrl: string) => {
      setQuery(queryUrl);
      setIsFilterChanged(true);
    };

    const prepareRequest = () => {
      const filterUrl = prepareFilterString(query, filter);

      const queryString = listQueryString({
        pagination: paginationModel,
        sort: prepareSort(sortModel, defaultSort),
        filter: {}
      });

      return `${queryString}${filterUrl}`;
    };

    const getProperties = async () => {
      setErrorMessage('');
      setIsFilterChanged(false);

      const request = prepareRequest();

      setIsLoading(true);
      const result = await opportunityService.getList(request);
      setIsLoading(false);

      if (result.isError) {
        setErrorMessage(result.errorMessage.message);
        return;
      }

      setProperties(result.data.data);
      setRowCountState(result.data.meta.total);
    };

    const getTableColumnsToShow = async () => {
      const response = await userPreferenceService.getItem<string[]>({
        category: dashboard,
        subcategory: DASHBOARDS.SELECTED_COLUMNS,
        module: fieldLabel.opportunities
      });

      if (response.isSuccess && response.data) {
        setSelectedColumns(response.data.contents);
      }
    };

    const udpateRefreshColumn = () =>
      setRefreshColumn((prevRefreshColumn) => prevRefreshColumn + 1);

    useEffect(() => {
      if (!initialLoad) return;

      getProperties();
    }, [paginationModel.page]);

    useEffect(() => {
      if (!initialLoad) return;

      if (paginationModel.page === 0) {
        getProperties();
      } else {
        setPaginationModel(Object.assign({}, paginationModel, { page: 0 }));
      }
    }, [paginationModel.pageSize]);

    useEffect(() => {
      if (!initialLoad) return;

      if (paginationModel.page === 0) {
        getProperties();
      } else {
        setPaginationModel(Object.assign({}, paginationModel, { page: 0 }));
      }
    }, [JSON.stringify(sortModel)]);

    useEffect(() => {
      if (!isFilterChanged) return;

      if (!initialLoad) {
        getTableColumnsToShow();
      }

      getProperties();
      setInitialLoad(true);
    }, [isFilterChanged]);

    useEffect(() => {
      eventBus.on(`${dashboard}_refresh_columns`, () => {
        udpateRefreshColumn();
      });
    }, []);

    useEffect(() => {
      if (!initialLoad) return;
      getTableColumnsToShow();
    }, [refreshColumn]);

    return (
      <>
        <Filter
          filter={filter}
          setFilter={setFilter}
          updateFilter={updateFilter}
          setIsFilterChanged={setIsFilterChanged}
          showAll={showAll}
        />
        <DGComponent
          rows={properties}
          columns={getColumns()}
          rowCount={rowCountState}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          sortModel={sortModel}
          onSortModelChange={setSortModel}
          loading={isLoading}
          error={errorMessage}
        />
      </>
    );
  }

  return Component;
};

export default PropertiesHOC(DataGrid);
