import { ChangeEvent } from 'types/common-types';
import { ErrorValidation } from 'types/error-types';
import { ProfileEntity } from 'types/user';
import { toastError, toastSuccess } from 'event/toast-event';
import { useEffect, useState } from 'react';
import {
  getAlertMessage,
  preparePayload,
  validateProfile
} from 'validations/profile';
import CancelButton from 'components/form/button-cancel';
import initialProfile from 'state/profile/initial-profile';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import RecordView from '../record-view';
import SaveButton from 'components/form/button-save';
import StackRowWithDivider from 'components/stack/stack-row-with-divider';
import useRouteName from 'pages/route-outlet-context';
import userService from 'services/user-service';
import { isEmpty } from 'helpers/misc-helper';
import { CreateResponse, UpdateResponse } from 'types/api-response-types';
import { Skeleton } from '@mui/material';
import FormContainer from 'components/form/container';
import UnitItem from 'components/form/unit-item';
import ModalHeader from 'components/modal/modal-header';
import Divider from '@mui/material/Divider';

import { TextFieldSkeleton } from 'components/form/unit-text';
import PaperBoxHeader from 'components/paper-box/paper-box-header';

const ProfileCreate = ({
  routeTag,
  userId,
  onCancel = () => {}
}: {
  routeTag: string;
  userId?: string | null;
  onCancel?: (reload: boolean) => void;
}) => {
  const [profile, setProfile] = useState<ProfileEntity>(initialProfile);
  const { setRouteName } = useRouteName();
  const [validation, setValidation] = useState<ErrorValidation>({});
  const [loading, setLoading] = useState<boolean>(
    isEmpty(userId) ? false : true
  );

  const handleChange = (e: ChangeEvent) => {
    setProfile((prevProfile) => ({
      ...prevProfile,
      [e.target.name]: e.target.value
    }));
  };

  const loadUser = async (id?: string | null) => {
    setLoading(true);
    const apiRequest = id ? userService.get(id) : userService.getCurrentUser();

    const result = await apiRequest;
    setLoading(false);

    if (result.isError) {
      toastError(result.errorMessage.message);
      return;
    }

    if (result.isSuccess) {
      setProfile(result.data);
    }
  };

  const handleSubmit = async () => {
    const { status, errors } = validateProfile(profile);
    setValidation(errors);
    if (!status) return;
    setLoading(true);

    const payload = preparePayload(profile);
    let result: CreateResponse<ProfileEntity> | UpdateResponse<ProfileEntity>;
    if (!isEmpty(profile.id)) {
      result = await userService.update(profile.id, payload);
    } else {
      result = await userService.create(payload);
    }
    setLoading(false);

    if (result.isValidationError) {
      setValidation(result.validationMessage);
      return;
    }

    if (result.isError) {
      toastError(result.errorMessage.message);
      return;
    }

    if (result.isSuccess) {
      toastSuccess(getAlertMessage(routeTag));
      if (onCancel) onCancel(true);
    }
  };

  useEffect(() => {
    if (routeTag === 'user-add') return;
    loadUser(userId);
  }, [routeTag]);

  useEffect(() => {
    setRouteName(routeTag);
  }, []);

  const showSkelton = loading && isEmpty(profile.email);

  return (
    <>
      <PaperBox>
        {routeTag !== 'profile-edit' && (
          <UnitItem grid={{ xs: 12, sm: 12 }}>
            <PaperBoxHeader
              value={
                <ModalHeader
                  title={routeTag === 'user-add' ? 'Add User' : 'Edit User'}
                  onClose={() => {
                    onCancel(false);
                    setProfile(initialProfile);
                  }}
                />
              }
              sx={{ border: 'none' }}
            />

            <Divider style={{ margin: '10px 0' }} />
          </UnitItem>
        )}

        <PaperBoxContent>
          {showSkelton ? (
            <FormContainer>
              <TextFieldSkeleton />
              <TextFieldSkeleton />
              <TextFieldSkeleton />
              <TextFieldSkeleton />
              <TextFieldSkeleton />
              <TextFieldSkeleton />
            </FormContainer>
          ) : (
            <>
              <RecordView
                profile={profile}
                validation={validation}
                handleChange={handleChange}
                routeTag={routeTag}
              />

              <StackRowWithDivider>
                <SaveButton onClick={handleSubmit} disabled={loading} />
                <CancelButton
                  onClick={() => {
                    onCancel(false);
                    setProfile(initialProfile);
                  }}
                />
              </StackRowWithDivider>
            </>
          )}
        </PaperBoxContent>
      </PaperBox>
    </>
  );
};
export default ProfileCreate;
